import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Felicia Cindy Winata';
export const GIRL_NAME_SHORT = 'Cindy';
export const GIRL_FATHER_NAME = `Ali Winata`;
export const GIRL_MOTHER_NAME = `Christina`;
export const GIRL_PARENT_NAME = 
  LANG === 'en'
    ? `The Daughter of<br /> Mr. ${GIRL_FATHER_NAME} & Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari Bapak ${GIRL_FATHER_NAME} & Ibu ${GIRL_MOTHER_NAME}`
;
// -> boy section
export const BOY_NAME = 'Damien Errol Jonathans';
export const BOY_NAME_SHORT = 'Damien';
export const BOY_FATHER_NAME = `Errol Jonathans`;
export const BOY_MOTHER_NAME = `Nunung Errol Jonathans`;
export const BOY_PARENT_NAME = 
  LANG === 'en'
    ? `The Son of<br /> Mr. ${BOY_FATHER_NAME} & Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari Bapak ${BOY_FATHER_NAME} & Ibu ${BOY_MOTHER_NAME}`
;
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'damienjonathans';
export const IG_GIRL = 'fcindyw';
export const IG_FILTER = `https://www.instagram.com/ar/327144676579591/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/masansgaming/Damien%20Felicia/SEO_rD1KpqFis.jpg?updatedAt=1698816526260`;
export const SEO_URL = 'https://invitato.net/DamienCindybigday';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1702096200;
export const EPOCH_RESEPSI_START = 1702096200;
export const EPOCH_END_EVENT = 1702130400;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-12-09');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_AKAD_TIME = '11.30 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Gereja Maria Bunda Karmel';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Karmel Raya No.2, RT.2/RW.4, Kb. Jeruk, Kec. Kb. Jeruk, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11530`;
export const WEDDING_AKAD_DRESSCODE = `Formal Attire`;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `Arts Café by Raffles`;
export const WEDDING_RESEPSI_LOC_ROAD = `Ciputra World 1, Jl. Prof. DR. Satrio No.5, RT.18/RW.1, Kuningan, Karet Kuningan, Jakarta, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12940`;
export const WEDDING_RESEPSI_DRESSCODE = `Shades of Brown`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');
export const WEDDING_RESEPSI_DATE = WEDDING_AKAD_FULLDATE;
// export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
//   .locale(DEFAULT_LANGUAGE)
//   .format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://DamienCindybigday.com/';
export const BROADCAST_WEDDING_LOCATION = `Arts Café by Raffles`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://www.google.com/maps/place/Arts+Cafe+By+Raffles/@-6.2241917,106.8214091,17z/data=!3m1!4b1!4m20!1m13!4m12!1m4!2m2!1d106.8204032!2d-6.1505536!4e1!1m6!1m2!1s0x2e69f3fb868429b7:0xfff19a4591765cb1!2sarts+cafe+by+raffles+link+google+maps!2m2!1d106.823984!2d-6.224197!3m5!1s0x2e69f3fb868429b7:0xfff19a4591765cb1!8m2!3d-6.224197!4d106.823984!16s%2Fg%2F11bw3dqc6v?entry=ttu`;
export const GOOGLE_MAPS_ADDRESS = `Showed in wedding details`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */

export const SOUND_BY = `Trisouls – Menarilah`;
export const SOUND_URL = 'https://www.youtube.com/watch?v=eZ71sfo2ixc';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/ApX0LaS6gSM';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/ApX0LaS6gSM';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/sP9vtH6Zic8`;
export const YOUTUBE_PREWEDDING_LINK = `https://www.youtube.com/watch?v=sP9vtH6Zic8`;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbxJxs_9cxVeJRW1ReoIVqHUOSQjb15QwWbSGSqE49ddrDiq77CuqP1QDfhW8KE6e_NE/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/dindadipoyono/';
export const URL_WA_INVITATO =
  'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';
